body {
    /* background: #1C8EF9 !important; */
    min-height: 100vh;
    /* display: flex; */
    /* font-weight: 200; */
    /* font-family: "Fira Sans", sans-serif; */
}

/* h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
} */

body,
html,
.App,
#root,
.auth-wrapper {
    width: 100%;
    height: 100%;
}

label {
    display: block;
}

a {
    text-decoration: none;
    
}

.navbar-nav a{
    --bs-nav-link-color: #fff
}
.bd-navbar {
    background-color: #0a35c4  !important;
}

.bd-navbar a.active {
    background-color: #306dca !important;
}

.card-container.card {
    max-width: 400px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.login-btn-w {
    width: 100%;
}
.password-label-left {
    float: left;
}
.password-label-right {
    float: right;
}

.accounts-list-table {
    width: 100%;
}

.container .nav-item a.active {
    background-color: #606061 !important;
    font-weight: 600;
}
.app-modal {
    max-height: 100vh;
    overflow-y: auto;
}

.pageHeader {
    width: 100%;
    display: flex;
    padding-top: 20px;
}

.pageHeader #header {
    width: 50%;
    margin-right: auto;
}

.pageHeader #header-add-btn {
    float: right;
}

.addButtonHeader {
    width: 100%;
    display: flex;
}
.addButtonHeader #header {
    width: 20%;
    margin-right: auto;
}
.addButtonHeader #header-add-btn {
    float: right;
}

.formView {
    width: 98%;
}

.change-password-form {
    width: 40%;
}
.shadow-bg {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 5px 0px 5px 0px;
}

.table-action-icon {
    cursor: pointer;
}
.txt-label {
    font-weight: bold;
    font-size: medium;
    display: inline-block;
}
.w-15 {
    width: 20%;
}
.txt-label-value {
    font-size: medium;
    padding: 0px 10px 0px 10px;
}
.new-line {
    height: 10px;
}

.accordion .accordion-button {
    color: #0a0a0a;
    font-weight: bold;
    background-color: #cacaca;
    padding-left: 5px;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    border-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-button:focus {
    border-color: #cacaca !important;
    color: #0a0a0a;
    border-width: 1px 0 0;
}
.accordion-button:active {
    border-color: #cacaca !important;
}
.accordion-button:not(.collapsed)::after {
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: '';
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button:not(.collapsed) {
    color: #0a0a0a;
    font-weight: bold;
    background-color: #cacaca;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

/* .rdt_TableRow {
    font-size: 15px !important;
}
.rdt_TableCol{
    font-size: 15px !important;
} */
.rdt_TableCell div:first-child {
    white-space: unset !important;
    overflow: unset !important;
}

.note {
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #d4d0d0 !important;
}
.float-right {
    float: right;
}
.phone-number input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.red {
    color: rgb(182, 6, 6);
}
.icon-color {
    color: #0a53be;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0a53be;
    --bs-btn-border-color: #0a53be;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.optionListContainer {
    position: relative !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    position: relative !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.associate-button{
        margin-top: 25px;
}

::-ms-reveal {
    display: none;
}

.password-div .eye-icon{
    position: relative;
    right: -289px;
    top: -35px;
}